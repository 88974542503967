import React from 'react';

import AuthenticationLayout from '~/layouts/authentication';
import { LinkComponent } from '~/components/Links';

import { ResetPasswordForm } from '~/components/forms';

const ResetPassword: React.FC = () => {
  return (
    <AuthenticationLayout
      title="Reset Password"
      primaryHeading="Reset Password"
      primaryDescription=""
      primaryForm={<ResetPasswordForm />}
      secondaryForm={<div />}
    >
      <LinkComponent href="/sign-in" arrow>
        Sign in
      </LinkComponent>
    </AuthenticationLayout>
  );
};

export default ResetPassword;
